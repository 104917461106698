import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import ANALYTIC_EVENTS from "@/feature-analytics/analytic-events.mjs";
import { refs__PRIVATE as refs } from "@/feature-analytics/tracker.mjs";

export function onComingSoonNotifySubmitted(
  gameSymbol: GameSymbol,
  email: string,
): Promise<boolean> {
  return refs.trackEvent(ANALYTIC_EVENTS.COMING_SOON_LAUNCH_EMAIL, {
    game: GAME_SHORT_NAMES[gameSymbol],
    email,
    [refs.isImmediate]: true,
  });
}

export function onMatchtileShare(
  gameSymbol: GameSymbol,
  gameData: object,
): Promise<boolean> {
  return refs.trackEvent(ANALYTIC_EVENTS.MATCH_TILES_SHARING, {
    game: GAME_SHORT_NAMES[gameSymbol],
    timestamp: new Date().toUTCString(),
    ...gameData,
  });
}
