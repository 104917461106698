import router from "@/__main__/router.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import ANALYTIC_EVENTS from "@/feature-analytics/analytic-events.mjs";
import { refs__PRIVATE as refs } from "@/feature-analytics/tracker.mjs";
import searchRefs from "@/search/refs.mjs";
import type { SearchResult } from "@/search/types.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import { stringifyPath } from "@/util/path-regexp.mjs";

const originals = mapOriginalRefs({
  searchRefs,
});

let isSearching: boolean;
let timeOpenedSearch: number;
let searchOpenedOnRoute: string;
let searchResult: SearchResult;
let resultFromRecentSearch: boolean;

function trackSearchOpen() {
  isSearching = true;
  timeOpenedSearch = Date.now();
  searchOpenedOnRoute = stringifyPath(router.route.path);
  searchResult = null;
  resultFromRecentSearch = false;
}

async function trackSearchClose() {
  if (!isSearching) return;
  isSearching = false;

  const timeUsedSearch = Date.now() - timeOpenedSearch;
  const eventData = {
    timeUsedSearch,
    searchOpenedOnRoute,
    searchAborted: !searchResult,
    resultFromRecentSearch,
  };
  if (searchResult) {
    Object.assign(eventData, {
      searchResultGame: GAME_SHORT_NAMES[searchResult.game],
      searchResultCategory: searchResult.category,
      searchResultName: searchResult.name,
      searchResultTag: searchResult.tagLine,
    });
  }

  await refs.trackEvent(ANALYTIC_EVENTS.SEARCH, eventData);
}

function trackSearchResultClick(
  result: SearchResult,
  fromRecentSearches: boolean,
) {
  searchResult = result;

  // Only track if it is, since it gets refired by the general click handler
  if (fromRecentSearches) resultFromRecentSearch = fromRecentSearches;
}

export function setup() {
  originals.set({
    searchRefs: {
      trackSearchOpen: trackSearchOpen,
      trackSearchClose: trackSearchClose,
      trackSearchResultClick: trackSearchResultClick,
    },
  });
}

export function teardown() {
  originals.restore();
}
