import ANALYTIC_EVENTS from "@/feature-analytics/analytic-events.mjs";
import { refs__PRIVATE as refs } from "@/feature-analytics/tracker.mjs";
import { devLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

function handleBlur() {
  setTimeout(async () => {
    const iframe = globals.document.activeElement;
    if (iframe.tagName !== "IFRAME") return;
    const iframeId = iframe.id;
    // heuristic-ish
    if (!iframeId.startsWith("google_ads_iframe_")) return;
    devLog(`iframe clicked ${iframeId}`);
    globals.focus();
    await refs.trackEvent(ANALYTIC_EVENTS.IFRAME_CLICKED, {
      iframeId,
      url: globals.location.href,
    });
  });
}

export function setup() {
  globals.addEventListener("blur", handleBlur);
}

export function teardown() {
  globals.removeEventListener("blur", handleBlur);
}
