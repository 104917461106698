import router from "@/__main__/router.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import sourceMap from "@/util/error-sourcemap.mjs";
import globals from "@/util/global-whitelist.mjs";

async function errorListener(event) {
  // filename is unintuitively the URL
  const { error, filename } = event;

  // sometimes this listener is called without an error?
  if (!error) return;

  // we only wanna report our own errors
  const tld = new URL(filename).hostname.match(/([a-z]+\.?[a-z]+)$/)?.[0];
  if (!globals.location?.hostname.includes(tld)) return;

  const [mappedOutput] = await sourceMap(error);

  eventBus.emit(EVENT_ERROR, {
    error,
    mappedOutput,
    tags: ["global"],
    route: router?.route?.currentPath,
  });
}

export function setup() {
  globals.addEventListener("error", errorListener);
  // globals.addEventListener("unhandledrejection", unhandledListener);
}

export function teardown() {
  globals.removeEventListener("error", errorListener);
  // globals.removeEventListener("unhandledrejection", unhandledListener);
}
