// import { IS_CHINA } from "@/__main__/constants.mjs";
// import * as baiduAnalytics from "@/feature-analytics/baidu.mjs";
import appRefs from "@/app/refs.mjs";
import * as genericError from "@/feature-analytics/generic-error.mjs";
import * as googleAnalytics from "@/feature-analytics/google.mjs";
import {
  onComingSoonNotifySubmitted,
  onMatchtileShare,
} from "@/feature-analytics/hooks-marketing.mjs";
import * as iframeClick from "@/feature-analytics/iframe-click.mjs";
import * as mouseover from "@/feature-analytics/mouseover.mjs";
import * as scrolling from "@/feature-analytics/scrolling.mjs";
import * as search from "@/feature-analytics/search.mjs";
import * as analytics from "@/feature-analytics/tracker.mjs";
import * as utmInfo from "@/feature-analytics/utm.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const providers = [
  // IS_CHINA ? baiduAnalytics : googleAnalytics,
  googleAnalytics,
  analytics,
  utmInfo,
  genericError,
  mouseover,
  scrolling,
  search,
  iframeClick,
];

export const isDeferred = true;

const original = mapOriginalRefs({
  appRefs,
});

export function setup() {
  // async load script to accelerate page loading
  // XXX: side effect: we may lose some track of page visiting
  //      if some analytics libs loading very slow
  providers.map(({ setup }) => setup());

  original.set({
    appRefs: {
      onComingSoonNotifySubmitted,
      onMatchtileShare,
    },
  });

  return Promise.resolve();
}

export function teardown() {
  original.restore();

  return Promise.all(providers.map(({ teardown }) => teardown()));
}
